.buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

table thead th.new-date {
  background-color: #6a5687;
  border-right: solid 1px white;
  color: #fff;
  .MuiBox-root input {
    background-color: white;
  }
  .MuiInputAdornment-root button.MuiButtonBase-root {
    color: white;
  }
}
