$fa-font-path: '../webfonts';
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';

table {
  border-spacing: 0;
  td,
  th {
    padding: 0px 10px;
    white-space: nowrap;
  }
  th {
    border-right: dotted 1px white;
    text-align: left;
    &:first-of-type {
      border: none;
    }
    &:last-of-type {
      border: none;
    }
    &.date {
      background-color: #6a5687;
      color: #fff;
    }
  }
  td,
  th.time {
    background-color: #d9eff7;
    color: #333;
  }
  td.decision {
    border: solid 1px white;
    border-left: none;
    border-bottom: none;
    text-align: center;
    i {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  tbody tr:first-child td {
    border-top: solid 1px white;
  }
  td.name {
    border-right: solid 1px white;
    cursor: pointer;
    & > div {
      display: block;
      position: relative;
      & > div {
        background-color: #c3c7f8;
        display: none;
        left: auto;
        padding: 0 5px;
        position: absolute;
        right: -10px;
        width: auto;
      }
    }
    &.active > div > div {
      display: inline;
      i {
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.fa-solid.active {
  background-color: rgba($color: #000000, $alpha: 0.3);
}

.fa-solid.decide {
  cursor: pointer;
}
